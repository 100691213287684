import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";



// Create styles
const styles = StyleSheet.create({
    rotatedSection: {
        transform: "rotate(-90deg)",
        textAlign: "left",
        marginTop: 280,
        width: 600,
        marginLeft: -285,
        // borderStyle:"solid",
        // borderColor:"#000",
        // borderWidth:1,
    },
    rotatedText: {
        fontSize: 6,
    },
});

interface RafoodSpallaPdfProps {
    colore?:string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const RafoodSpallaPdf = ({colore}: RafoodSpallaPdfProps) => {

    return (
        <>
            <View key={'colonne_principali'} style={{flexDirection: "column", justifyContent: "center",}}>
                <View key={'rettangolo_colorato'} style={{height: '200px', backgroundColor:colore }}></View>
                <View key={'testo_ruotato'} >
                    <View style={styles.rotatedSection}>
                        <Text style={styles.rotatedText}>
                            I numeri dall' 1 al 15 devono essere compilati sotto la responsabilità del mittente, oltre ai numeri 19-21-22.
                            Le parti tracciate in grassetto devono essere compilate dal trasportatore.
                        </Text>
                        <View style={{flexDirection: "row"}}>
                            <View style={{flexDirection: "column"}}>
                                <Text style={styles.rotatedText}>To be completed on the sender's responsability</Text>
                                <Text style={styles.rotatedText}>Auszufüllen unter der Verantwortung des Absenders</Text>
                            </View>
                            <Text style={{marginLeft:10,marginRight:10, fontSize: 16,}}>1 -15</Text>
                            <View style={{flexDirection: "column"}}>
                                <Text style={styles.rotatedText}>including and</Text>
                                <Text style={styles.rotatedText}>einschließlich</Text>
                            </View>
                            <Text style={{marginLeft:10,marginRight:10, fontSize: 16,}}>19+21+22</Text>

                            <View style={{flexDirection: "column"}}>
                                <Text style={styles.rotatedText}>The spaces framed with heavy lines must be filled in by the carrier.</Text>
                                <Text style={styles.rotatedText}>Die mit fett gedruckten Linien eingerahmten Rubriken müssen vom Frachtführer ausgefüllt werden</Text>
                            </View>
                        </View>
                    </View>
                </View>


            </View>

        </>
    );
}

export default RafoodSpallaPdf;
