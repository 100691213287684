import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../redux/store";
import {PDFViewer, Page, Document, StyleSheet} from "@react-pdf/renderer";
import moment from "moment";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";
import {
    fetchPackingListPallets,
    getPackingList,
    getPallet,
    PackingList,
    Pallet,
    PesaturaPalletsState
} from "../pesaturaPalletSlice";
import RafoodPdf from "./rafoodPdf/RafoodPdf";


const RafoodStampaPage = () => {
    moment.locale("it");
    const dispatch = useAppDispatch();
    const {packing_list_id} = useParams();
    const packingListId: number = parseInt(packing_list_id || '0');

    useEffect(() => {
        packingListId && dispatch(getPackingList({packing_list_id: packingListId}));
    }, [packingListId]);


    const packingList = useSelector<RootState, PackingList | undefined>(
        (state) => state.pesaturaPalletState.packingListState.results.find(
            (packingList) => packingList.id == packingListId
        )
    );

    useEffect(() => {
        packingList?.id && dispatch(fetchPackingListPallets({packing_list_id: packingList.id}));
    }, [packingList]);

    const pallets: PesaturaPalletsState = useSelector<RootState, PesaturaPalletsState>(
        (state) => state.pesaturaPalletState.pallets
    );

    return (
        <Box>
            <Box sx={{backgroundColor: "#999", height: "80vh"}}>
                <PDFViewer width={"100%"} height={"100%"}>
                    <Document>
                        <Page
                            size="A4"
                            orientation="portrait"
                            style={{
                                backgroundColor: "#fff",
                                padding: 20,
                            }}
                            key={"RafoodStampaPage_" + packing_list_id}
                        >
                            {packingList && <RafoodPdf packingList={packingList}
                                                       colore={'red'}
                                                       numeroPagina={'1'}
                                                       testoItaliano={'Esemplare per il mittente'}
                                                       testoFrancese={'Exaimplaire pour l\'expéditeur'}
                                                       testoInglese={'Copy for sender'}
                                                       testoTedesco={'Exampalr für den Absender'}
                            />}
                        </Page>
                        <Page
                            size="A4"
                            orientation="portrait"
                            style={{
                                backgroundColor: "#fff",
                                padding: 20,
                            }}
                            key={"RafoodStampaPage_" + packing_list_id}
                        >
                            {packingList && <RafoodPdf packingList={packingList} colore={'#0000d7'}
                                                       numeroPagina={'2'}
                                                       testoItaliano={'Esemplare per il Destinatario'}
                                                       testoFrancese={'Exaimplaire pour le Destinataire'}
                                                       testoInglese={'Copy for consignee'}
                                                       testoTedesco={'Exampalr für den Empfanger'} />}
                        </Page>
                        <Page
                            size="A4"
                            orientation="portrait"
                            style={{
                                backgroundColor: "#fff",
                                padding: 20,
                            }}
                            key={"RafoodStampaPage_" + packing_list_id}
                        >
                            {packingList && <RafoodPdf packingList={packingList} colore={'#00aa00'} numeroPagina={'3'}
                                                       testoItaliano={'Esemplare per il Trasportatore'}
                                                       testoFrancese={'Exaimplaire pour le Trasporteur'}
                                                       testoInglese={'Copy for Carier'}
                                                       testoTedesco={'Exampal für Franchtfuhrer'}/>}
                        </Page>
                        <Page
                            size="A4"
                            orientation="portrait"
                            style={{
                                backgroundColor: "#fff",
                                padding: 20,
                            }}
                            key={"RafoodStampaPage_" + packing_list_id}
                        >
                            {packingList && <RafoodPdf packingList={packingList} colore={'#808080'}
                                                       numeroPagina={'4'}
                                                       testoItaliano={'Esemplare per il Controllo'}
                                                       testoFrancese={'Exaimplaire pour le Controle'}
                                                       testoInglese={'Copy for control'}
                                                       testoTedesco={'Exampal für Kontroll'} />}
                        </Page>
                        <Page
                            size="A4"
                            orientation="portrait"
                            style={{
                                backgroundColor: "#fff",
                                padding: 20,
                            }}
                            key={"RafoodStampaPage_" + packing_list_id}
                        >
                            {packingList && <RafoodPdf packingList={packingList}
                                                       numeroPagina={''}
                                                       testoItaliano={'Copia Uso interno'}
                                                       testoFrancese={' '}
                                                       testoInglese={' '}
                                                       testoTedesco={' '} />}
                        </Page>
                    </Document>
                </PDFViewer>
            </Box>
        </Box>
    );
};

export default RafoodStampaPage;
