import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../redux/store";
import {getLotto, Lotto} from "components/main/pianificazione/pianificazioneSlice";
import {
    Lavorazioni,
    Lavorazione,
    fetchLavorazioni,
} from "components/main/lavorazioni/lavorazioniSlice";
import GrandeImballoDdtPdf from "components/main/pesaturaPallet/stampe/GrandeImballoDdtPdf";
import {PDFViewer, Page, Document, StyleSheet} from "@react-pdf/renderer";
import moment from "moment";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "components/common/errorsDeclarations";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";
import {fetchPackingListPallets, getPackingList, getPallet, PackingList, Pallet} from "../pesaturaPalletSlice";

// Create styles
interface GrandeImballoDdtStampaPageProps {
}

const GrandeImballoDdtStampaPage = () => {
    moment.locale("it");
    const dispatch = useAppDispatch();
    const {pallet_id} = useParams();
    const palletId: number = parseInt(pallet_id || '0');

    useEffect(() => {
        palletId && dispatch(getPallet({pallet_id: palletId}));
    }, [palletId]);


    const pallet = useSelector<RootState, Pallet>((state) => state.pesaturaPalletState.pallets.results.find(p => p.id == palletId) || {} as Pallet);

    useEffect(() => {
        pallet.lotto && dispatch(getLotto( pallet.lotto));
    }, [pallet.lotto]);

    const lotto = useSelector<RootState, Lotto>((state) => state.pianificazione.lotti.results.find(l => l.id == pallet.lotto) || {} as Lotto);

    return (
        <Box>
            <Box sx={{backgroundColor: "#999", height: "80vh"}}>
                <PDFViewer width={"100%"} height={"100%"}>
                    <Document>
                        <Page
                            size="A4"
                            orientation="portrait"
                            style={{
                                backgroundColor: "#fff",
                                paddingTop: 20,
                            }}
                            key={"grande_imballo_packing" + pallet_id}
                        >
                            {pallet && <GrandeImballoDdtPdf pallet={pallet} lotto={lotto}/>}
                        </Page>
                    </Document>
                </PDFViewer>
            </Box>
        </Box>
    );
};

export default GrandeImballoDdtStampaPage;
