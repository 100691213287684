import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood1PdfProps {
    // colore: string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood1Pdf = ({}: Rafood1PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:5
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 16,}}>1</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 10,}}
                    >
                        <Text style={{fontSize: 6,}}>
                            Mittente
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Sender
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Absender
                        </Text>
                    </View>
                </View>
                <View style={{flexDirection: "column", marginTop:5}}>
                    <Text style={{fontSize: 10,}}>Zarattini Menotti slr</Text>
                    <Text style={{fontSize: 10,}}>via Leonardo da Vinci 50</Text>
                    <Text style={{fontSize: 10,}}>35018 San Martino di Lupari (PD)</Text>
                    <Text style={{fontSize: 10, marginTop:3}}>by order of EM Poultry srl</Text>
                    <Text style={{fontSize: 10,}}>via Procaccini 22</Text>
                    <Text style={{fontSize: 10,}}>20154 Milano (MI)</Text>
                </View>


            </View>

        </>
    );
}

export default Rafood1Pdf;
