import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../pesaturaPalletSlice";

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: "#fff",
        paddingTop: 20,
    },
    section: {
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        padding: 10,
    },
    sectionIntestazione: {
        // width: "100%",
        marginTop: 10,
        marginHorizontal: 20,
        padding: 5,
        // paddingTop: 10,
        // paddingBottom: 7,
        // backgroundColor: "#777777",
        // color: "#ffffff",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#000",
    },
    text: {
        fontSize: 15,
        fontStyle: "medium",
        // marginVertical: 20,
    },
    sectionTable: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: 0,
        borderBottomWidth: 1,
    },
    sectionFrame: {
        flexDirection: "row",
        paddingHorizontal: 60,
        paddingVertical: 40,
        fontSize: 180,
        fontStyle: "medium",
        borderWidth: 1,
    },
});

interface PackingListPdfProps {
    packingList: PackingList;
    pallets: Pallet[];
}

const PackingListPdf = ({packingList, pallets}: PackingListPdfProps) => {

    return (
        <>
            <View
                key={"data_" + packingList.id}
                // style={[
                //     styles.sectionIntestazione,
                //     {
                //         // borderWidth: 1,
                //         flexDirection: "column",
                //         justifyContent: "center",
                //     },
                // ]}
            >
                <Text style={[styles.text, {
                    marginTop: 10,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 16,
                    paddingBottom: 1,
                    marginLeft: 25
                },]}>
                    {moment(packingList.data).format("dddd DD MMM YYYY")}
                </Text>
            </View>
            <View
                key={"etichetta_schedaCarico_" + packingList.id}
                style={[
                    styles.sectionIntestazione,
                    {
                        // borderWidth: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                    },
                ]}
            >
                <View
                    key={"Intestazioni_ita" + packingList.id}
                    style={{
                        borderWidth: 0,
                        flexDirection: "row",
                    }}
                >
                    <Text style={[styles.text, {fontSize: 10, width: "23%",}]}>Descrizione prodotto</Text>
                    <Text style={[styles.text, {
                        fontSize: 12, width: "7%",
                        // borderLeftStyle:"solid",
                        // borderLeftWidth: 1,
                        // borderLeftColor: "#555",
                    }]}>Pal. ID</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%",}]}>Lotto nr.</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>Prododuz.</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>Scadenza</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>P. Netto</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>P. Lordo</Text>
                </View>
                <View
                    key={"Intestazioni_ita" + packingList.id}
                    style={{
                        borderWidth: 0,
                        flexDirection: "row",
                    }}
                >
                    <Text style={[styles.text, {fontSize: 10, width: "23%",}]}>Product description</Text>
                    <Text style={[styles.text, {
                        fontSize: 12, width: "7%",
                        // borderLeftStyle:"solid",
                        // borderLeftWidth: 1,
                        // borderLeftColor: "#555",
                    }]}></Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%",}]}>Lots nrs</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>Production</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>Expiry</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>Net weight</Text>
                    <Text style={[styles.text, {fontSize: 12, width: "14%"}]}>Gross weight</Text>
                </View>
            </View>

            <View
                key={"etichetta_schedaCarico_" + packingList.id}
                style={[
                    styles.sectionIntestazione,
                    {
                        // borderWidth: 1,
                        marginTop: 0,
                        flexDirection: "column",
                        justifyContent: "center",
                    },
                ]}
            >
                {pallets.map(pallet =>
                    <View
                        key={"pallet_line_" + pallet.id}
                        style={{
                            borderWidth: 0,
                            flexDirection: "row",
                        }}
                    >
                        <Text style={[styles.text, {fontSize: 8, width: "23%",}]}>Galline Fresche/Fresh Hens</Text>
                        <Text style={[styles.text, {
                            fontSize: 10, width: "7%",
                            // borderLeftStyle:"solid",
                            // borderLeftWidth: 1,
                            // borderLeftColor: "#555",
                        }]}>{pallet.id}</Text>
                        <Text style={[styles.text, {
                            fontSize: 10,
                            width: "14%",
                        }]}>{pallet.lotto_codice_tracciabilita}</Text>
                        <Text style={[styles.text, {
                            fontSize: 10,
                            width: "14%"
                        }]}>{moment(pallet.data_produzione).format("DD/MM/YYYY")}</Text>
                        <Text style={[styles.text, {
                            fontSize: 10,
                            width: "14%"
                        }]}>{moment(pallet.data_scadenza).format("DD/MM/YYYY")}</Text>
                        <Text style={[styles.text, {
                            fontSize: 10,
                            width: "14%", textAlign: "right",
                        }]}>{pallet.peso_netto && Intl.NumberFormat("it-IT").format(pallet.peso_netto) + " kg"}
                        </Text>
                        <Text style={[styles.text, {fontSize: 10, width: "14%", textAlign: "right"}]}>
                            {pallet.peso_lordo && Intl.NumberFormat("it-IT").format(pallet.peso_lordo) + " kg"}
                        </Text>
                    </View>
                )}
            </View>
            <View
                key={"etichetta_schedaCarico_" + packingList.id}
                style={[
                    styles.sectionIntestazione,
                    {
                        // borderWidth: 1,
                        marginTop: 0,
                        flexDirection: "column",
                        justifyContent: "center",
                    },
                ]}
            >
                <View
                    key={"pallet_line_totali" + packingList.id}
                    style={{
                        borderWidth: 0,
                        flexDirection: "row",
                    }}
                >
                    <Text style={[styles.text, {fontSize: 8, width: "23%",}]}>Totali / Total:</Text>
                    <Text style={[styles.text, {
                        fontSize: 10, width: "7%",
                        // borderLeftStyle:"solid",
                        // borderLeftWidth: 1,
                        // borderLeftColor: "#555",
                    }]}>{packingList.num_pallets}</Text>
                    <Text style={[styles.text, {fontSize: 10, width: "42%",}]}></Text>
                    <Text style={[styles.text, {
                        fontSize: 10,
                        width: "14%", textAlign: "right",
                    }]}>{packingList.peso_netto && Intl.NumberFormat("it-IT").format(packingList.peso_netto) + " kg"}
                    </Text>
                    <Text style={[styles.text, {fontSize: 10, width: "14%", textAlign: "right"}]}>
                        {packingList.peso_lordo && Intl.NumberFormat("it-IT").format(packingList.peso_lordo) + " kg"}
                    </Text>
                </View>
            </View>
            <View
                key={"data_" + packingList.id}
            >
                <Text style={[styles.text, {
                    marginTop: 10,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 12,
                    paddingBottom: 1,
                    marginLeft: 25
                },]}>
                    Nr. CEE / EEC Number: IT 0307 M
                </Text>
                <Text style={[styles.text, {
                    marginTop: 10,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 12,
                    paddingBottom: 1,
                    marginLeft: 25
                },]}>
                    Targa Camion: {packingList.targhe_camion}
                </Text>
                <Text style={[styles.text, {
                    marginTop: 10,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 12,
                    paddingBottom: 1,
                    marginLeft: 25
                },]}>
                    Temperatura di trasporto / Transport temperature: 0° C
                </Text>
                <Text style={[styles.text, {
                    marginTop: 20,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 12,
                    paddingBottom: 1,
                    marginLeft: 25
                },]}>
                    Timbro del trasportatore
                </Text>
                <Text style={[styles.text, {
                    marginTop: 10,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 12,
                    paddingBottom: 1,
                    marginLeft: 25

                },]}>Transporter stamp and
                    signature:…........................................................... </Text>
                <Text style={[styles.text, {
                    marginTop: 20,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 12,
                    paddingBottom: 1,
                    marginLeft: 25
                },]}>Timbro e firma luogo di carico</Text>
                <Text style={[styles.text, {
                    marginTop: 10,
                    marginHorizontal: 40,
                    padding: 5,
                    fontSize: 12,
                    paddingBottom: 1,
                    marginLeft: 25
                },]}>Loading coldstore stamp and
                    signature:…........................................................................</Text>
            </View>
        </>
    );
}

export default PackingListPdf;
