import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood22PdfProps {
    // colore: string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood22Pdf = ({}: Rafood22PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding: 2,
                height: 90
            }}>
                <Text style={{fontSize: 9, }}>22</Text>
                <View
                    style={{flexDirection: "column", marginLeft: 2,}}
                >
                    <Text style={{fontSize: 5,}}>
                        Firma e timbro del mittente
                    </Text>
                    <Text style={{fontSize: 5,}}>
                        Signature and stamp of the sender
                    </Text>
                    <Text style={{fontSize: 5,}}>
                        Unterschrift und Stempel des Absenders
                    </Text>
                </View>


            </View>

        </>
    );
}

export default Rafood22Pdf;
