import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface RafoodIntestazioneCopiaPdfProps {
    colore?: string;

    numeroPagina: string;
    testoItaliano: string;
    testoFrancese: string;
    testoInglese: string;
    testoTedesco: string;
    // packingList: PackingList;
    // pallets: Pallet[];
}

const RafoodIntestazioneCopiaPdf = ({
                                        colore,
                                        numeroPagina,
                                        testoItaliano,
                                        testoFrancese,
                                        testoInglese,
                                        testoTedesco
                                    }: RafoodIntestazioneCopiaPdfProps) => {

    return (
        <>
            <View key={`intestazione_${colore}`} style={{flexDirection: "row", justifyContent: "space-between",}}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row", color: colore}}>
                    <Text style={{fontSize: 26,}}>{numeroPagina}</Text>
                    <View
                        key={"scritte_intestazione_" + colore}
                        style={{flexDirection: "column", marginLeft: 10,}}
                    >
                        <Text style={{fontSize: 10,}}>
                            {testoItaliano}
                        </Text>
                        <Text style={{fontSize: 10,}}>
                            {testoFrancese}
                        </Text>
                        <Text style={{fontSize: 10,}}>
                            {testoInglese}
                        </Text>
                        <Text style={{fontSize: 10,}}>
                            {testoTedesco}
                        </Text>
                    </View>
                </View>
                <View key={'rettangolo_codici_trasportare'}
                      style={{flexDirection: "row", borderStyle: "solid", borderColor: "#000", borderWidth: 2}}>
                    <View key={'rettangolo_codici_trasportare'}
                          style={{borderStyle: "solid", borderColor: "#000", borderWidth: 1, width: '120px'}}>
                        <Text style={{fontSize: 10,}}>Codice trasportatore</Text>
                        <Text style={{fontSize: 10,}}>Code of carrier</Text>
                        <Text style={{fontSize: 10,}}>Code Frachfuhre</Text>
                    </View>
                    <View key={'rettangolo_codici_trasportare'}
                          style={{borderStyle: "solid", borderColor: "#000", borderWidth: 1, width: '120px'}}>
                    </View>
                </View>


            </View>

        </>
    );
}

export default RafoodIntestazioneCopiaPdf;
