import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import moment from "moment";

import LottoForm from "./LottoForm";
import SchedeCaricoLottoTab from "./SchedeCaricoLottoTab";
import SchedeCaricoLottoCard from "../schedeCarico/schedeCaricoLottoCard/SchedeCaricoLottoCard";
import SchedaMacelloLottoCard from "../schedeMacello/SchedaMacelloLottoCard";
import LavorazioniLottoCard from "../lavorazioni/lavorazioniPianificazione/LavorazioniLottoCard";
import ConsuntiviVivoLottoList from "../schedeCarico/consuntiviVivo/ConsuntiviVivoLottoList";
import ReseLottoCard from "../reportistica/rese/ReseLottoCard";
import AllegatiLottoPage from "../allegati/AllegatiLottoPage";
import LottoCorrispettiviCard from "../bolle_fatture/corrispettivi/LottoCorrispettiviCard";
import {getColore, getColoreTipoCapo} from "../pianificazione/utility/lottoUtility";

import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { theme } from "../../../theme";

import {
	Lotto,
	getLotto,
	saveLotto,
	// setNuovoLotto,
} from "./pianificazioneSlice";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Committente,
	Produttore,
	Allevamento,
	Provincia,
	TipoCapo,
	Camion,
	Autista,
	Parametri,
} from "../anagrafiche/anagraficheSlice";
import {
	fetchschedeCarico,
	generaSchedeCarico,
	mandaM4,
} from "../schedeCarico/schedeCaricoSlice";
import { fetchConsuntiviVivo } from "../schedeCarico/consuntiviVivo/consuntiviVivoSlice";
import {
	saveSchedaMacello,
	SchedaMacello,
} from "../schedeMacello/schedeMacelloSlice";
import { Confezionamento } from "../lavorazioni/lavorazioniConsuntivazione/confezionamentiSlice";
import { Resa, fetchRese } from "../reportistica/rese/reseSlice";

import CamionsBox from "../schedeCarico/CamionsBox";
import AutistiBox from "../schedeCarico/AutistiBox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function WithoutTime(dateTime: Date) {
	var date = new Date(dateTime.getTime());
	date.setHours(0, 0, 0, 0);
	return date;
}

interface LottoPageProps {
	lotto_id?: number | null;
	dataCorrente: Date | null;
	handleClose: () => void;
}

const LottoPage = ({
	lotto_id,
	dataCorrente,
	handleClose,
	...props
}: LottoPageProps) => {
	const dispatch = useAppDispatch();
	const [currentTab, setCurrentTab] = useState("1");

	const lottoCorrente_id = useSelector<RootState, number | null>(
		(state) => state.pianificazione.lottoCorrente_id
	);

	useEffect(() => {
		if (lottoCorrente_id != null || lottoCorrente_id != undefined) {
			dispatch(getLotto(lottoCorrente_id));
		}
	}, [lottoCorrente_id, currentTab]);

	const lottoNuovoFieldsErrors: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.pianificazione.lotti.errorsStack);

	const [lotto, setLotto] = useState<Lotto>({
		id: null,
		stato_lotto: {
			stato: 0,
			descrizione: "pianificato",
			schede_carico_mancanti: 0,
			messaggio: "",
		},
		data_lavorazione: moment(dataCorrente).format("YYYY-MM-DD"),
		data_ordine_cliente: undefined,
		viaggio_lungo: false,
		ordinamento_lotto: 1,
		committente: null,
		committente_desc: "",
		produttore: null,
		produttore_pack: null,
		m4_inviato: false,
		m4_inviati: [],
		in_dubbio: false,
		note_dubbio: "",
		// confermato: false,
		codice_tracciabilita: undefined,
		calcolo_codice_tracciabilita_automatico: false,
		tipo_capo: null,
		tipo_capo_desc: undefined,
		colore: undefined,
		peso_medio: null,
		numero_capi: undefined,
		numero_capi_foro: 30,
		numero_fori: undefined,
		allevamento: null,
		allevamento_pack: undefined,
		resa_confermata: false,
		aggiustamento_resa_bst: true,
		note: "",
		schede_carico: [],
		consuntivi_vivo: [],
		lavorazioni_lotto: [],
		scheda_macello: null,
		errorsStack: lottoNuovoFieldsErrors,
	});

	useEffect(() => {
		setLotto({ ...lotto, errorsStack: lottoNuovoFieldsErrors });
	}, [lottoNuovoFieldsErrors]);

	const lottoEsistente: Lotto | undefined = useSelector<
		RootState,
		Lotto | undefined
	>((state) =>
		state.pianificazione.lotti.results.find(
			(lotto) => lotto.id == lottoCorrente_id
		)
	);

	useEffect(() => {
		lottoEsistente && setLotto(lottoEsistente);
	}, [lottoCorrente_id, lottoEsistente]);

	useEffect(() => {
		if (lottoEsistente?.data_lavorazione) {
			const data_da = new Date(lottoEsistente.data_lavorazione);
			const data_a = new Date(lottoEsistente.data_lavorazione);
			dispatch(fetchschedeCarico({ data_da, data_a }));
			dispatch(fetchConsuntiviVivo({ data_da, data_a }));
			// dispatch(fetchRese({ data_da, data_a }));
		}
	}, [lottoEsistente?.id]);

	useEffect(() => {
		if (lottoEsistente?.data_lavorazione) {
			const data_da = new Date(lottoEsistente.data_lavorazione);
			const data_a = new Date(lottoEsistente.data_lavorazione);
			if (currentTab == "3") {
				dispatch(fetchschedeCarico({ data_da, data_a }));
			}
			if (currentTab == "4") {
				dispatch(fetchConsuntiviVivo({ data_da, data_a }));
			}
			// dispatch(fetchRese({ data_da, data_a }));
		}
	}, [currentTab]);
	// const lotto: Lotto = lottoEsistente ? lottoEsistente : lottoNuovo;

	const errorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.pianificazione.lotti.errorsStack
	);

	const committenti = useSelector<RootState, Committente[]>(
		(state) => state.anagrafiche.committenti.results
	);

	const produttori = useSelector<RootState, Produttore[]>(
		(state) => state.anagrafiche.produttori.results
	);

	const allevamenti = useSelector<RootState, Allevamento[]>(
		(state) => state.anagrafiche.allevamenti.results
	);

	const tipiCapo = useSelector<RootState, TipoCapo[]>(
		(state) => state.anagrafiche.tipiCapo.results
	);
	const coloreTipoCapo = useSelector<RootState, TipoCapo | undefined>(
		(state) => state.anagrafiche.tipiCapo.results.find((tipoCapo) => tipoCapo.id == lotto.tipo_capo)
	);

	const camions: Camion[] = useSelector<RootState, Camion[]>(
		(state) => state.anagrafiche.camions.results
	);
	const autisti: Autista[] = useSelector<RootState, Autista[]>(
		(state) => state.anagrafiche.autisti.results
	);
	// const parametri: Parametri = useSelector<RootState, Parametri>(
	// 	(state) => state.anagrafiche.parametri
	// );

	const parametri = useSelector<RootState, Parametri | undefined>((state) =>
		state.anagrafiche.storicoParametri.results.find(
			(p) =>
				new Date(p.data_validta_da) <=
					new Date(
						new Date(lotto.data_lavorazione).getTime() + 6 * 60 * 60 * 1000
					) &&
				new Date(
					new Date(lotto.data_lavorazione).getTime() - 6 * 60 * 60 * 1000
				) <= new Date(p.data_validta_a)
		)
	);

	const [intestazione, setIntestazione] = useState("");
	// const [inAttesaConferma, setInAttesaConferma] = useState(false);
	const [poiChiudi, setPoiChiudi] = useState(false);
	const [styleBkg, setStyleBkg] = useState(false);

	useEffect(() => {
		setIntestazione(
			moment(lotto.data_lavorazione).format("dddd DD MMM") +
				" - Sequenza Macellazione " +
				lotto.ordinamento_lotto
		);
		setStyleBkg(lotto.in_dubbio);
	}, [lotto]);

	const handleTabChange = (event: any, newValue: string) => {
		setCurrentTab(newValue);
	};

	const handleSaveLotto = (lottoToSave: Lotto, poiChiudi: boolean = false) => {
		setPoiChiudi(poiChiudi);
		dispatch(saveLotto(lottoToSave));
		// setInAttesaConferma(true);
	};

	const handlerSaveSchedaMacello = (schedaMacelloToSave: SchedaMacello) => {
		const data_ora_inizio_macello_toSave: Date | string = new Date(
			moment(lotto.data_lavorazione).format("YYYY-MM-DD") +
				"T" +
				moment(schedaMacelloToSave.data_ora_inizio_macello).format("HH:mm:ss")
		);
		const data_ora_fine_macello_toSave: Date | string = new Date(
			moment(lotto.data_lavorazione).format("YYYY-MM-DD") +
				"T" +
				moment(schedaMacelloToSave.data_ora_fine_macello).format("HH:mm:ss")
		);
		dispatch(
			saveSchedaMacello({
				...schedaMacelloToSave,
				data_ora_inizio_macello: data_ora_inizio_macello_toSave,
				data_ora_fine_macello: data_ora_fine_macello_toSave,
			})
		);
	};

	const handlerMandaM4 = (lotto: Lotto, contatto_id: number) => {
		dispatch(mandaM4({ lotto, contatto_id }));
	};

	const handleAnnulla = () => {
		handleClose();
	};

	useEffect(() => {
		if (
			poiChiudi &&
			!errorsStack.saving &&
			errorsStack.status != ErrorStatusTypes.ERROR
		) {
			// setInAttesaConferma(false);
			handleClose();
		}
	}, [errorsStack.saving]);

	return (
		<Box
			sx={{
				bgcolor: styleBkg ? "rgba(27, 117, 177, 0.1)" : "white",
			}}
		>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={
					// lotto.errorsStack.status == ErrorStatusTypes.PENDING ||
					errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={4}
				sx={{ mb: 1,
					backgroundColor: lotto.in_dubbio
						? "rgba(250, 250, 250, 0.6)"
						: getColoreTipoCapo(coloreTipoCapo) || "background.paper",
				}}
			>
				<Avatar
					sx={{
						width: "1.5rem",
						height: "1.5rem",
						bgcolor: lotto.resa_confermata
							? theme.palette.success.main
							: theme.palette.primary.main,
						color: "white",
					}}
					aria-label="recipe"
				>
					{lotto.ordinamento_lotto}
				</Avatar>
				<Typography component="h3" variant="h5">
					{moment(lotto.data_lavorazione).format("dddd DD MMM")}
				</Typography>

				<Typography
					variant="h6"
					component="div"
					color="text.secondary"
					gutterBottom
				>
					{lotto.committente_desc}
				</Typography>
				<Typography
					variant="h6"
					component="div"
					color="text.secondary"
					gutterBottom
				>
					{lotto.produttore_pack?.nome}
				</Typography>
				<Typography
					variant="h6"
					component="div"
					color="text.secondary"
					gutterBottom
				>
					{lotto.numero_capi +
						" " +
						lotto.tipo_capo_desc +
						" " +
						(lotto.colore ? " " + getColore(lotto) : "")}
				</Typography>
				<Typography
					variant="h6"
					component="div"
					color="text.secondary"
					gutterBottom
				>
					{lotto.allevamento_pack?.nome}
				</Typography>
			</Stack>

			<TabContext value={currentTab}>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "divider",
					}}
				>
					<TabList
						onChange={handleTabChange}
						aria-label="lab API tabs example"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab label="Pianificazione" value="1" />
						<Tab
							label="Schede di carico"
							value="2"
							disabled={lotto.id == undefined}
						/>
						<Tab
							label="Lavorazioni previste"
							value="3"
							disabled={lotto.id == undefined || lotto.stato_lotto.stato == 0}
						/>
						<Tab
							label="Consuntivo Vivo"
							value="4"
							disabled={lotto.id == undefined || lotto.stato_lotto.stato < 3}
						/>
						<Tab
							label="Scheda Macello"
							value="5"
							disabled={lotto.id == undefined || lotto.stato_lotto.stato < 3}
						/>
						<Tab
							label="Lavorazioni consuntivo"
							value="6"
							disabled={lotto.id == undefined || lotto.stato_lotto.stato == 0}
						/>
						<Tab
							label="Resa Lotto"
							value="7"
							disabled={lotto.id == undefined || lotto.stato_lotto.stato < 3}
						/>
						<Tab
							label="Documenti"
							value="8"
							disabled={lotto.id == undefined || lotto.stato_lotto.stato < 3}
						/>
						<Tab
							label="Corrispettivi"
							value="9"
							disabled={lotto.id == undefined || lotto.stato_lotto.stato < 3}
						/>
					</TabList>
				</Box>
				<TabPanel value="1">
					<LottoForm
						lottoEdited={lotto}
						tipiCapo={tipiCapo}
						committenti={committenti}
						produttori={produttori}
						allevamenti={allevamenti}
						saveLotto={handleSaveLotto}
						handleAnnulla={handleAnnulla}
						setStyleBkg={setStyleBkg}
						// saving={false}
					/>
				</TabPanel>
				<TabPanel value="2">
					<SchedeCaricoLottoTab lotto={lotto} />
				</TabPanel>
				<TabPanel value="3">
					<LavorazioniLottoCard lotto={lotto} />
				</TabPanel>
				<TabPanel value="4">
					{parametri && (
						<ConsuntiviVivoLottoList lotto={lotto} parametri={parametri} />
					)}
				</TabPanel>
				<TabPanel value="5">
					<SchedaMacelloLottoCard
						lotto={lotto}
						saveSchedaMacello={handlerSaveSchedaMacello}
					/>
				</TabPanel>
				<TabPanel value="6">
					<LavorazioniLottoCard lotto={lotto} cosuntivazione={true} />
				</TabPanel>
				<TabPanel value="7">
					{lotto.id && <ReseLottoCard resa_id={lotto.id} />}
				</TabPanel>
				<TabPanel value="8">
					{lotto.id && <AllegatiLottoPage lotto={lotto} />}
				</TabPanel>
				<TabPanel value="9">
					{lotto.id && <LottoCorrispettiviCard lotto_id={lotto.id} />}
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default LottoPage;
