import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood3PdfProps {
    // colore: string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood3Pdf = ({}: Rafood3PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:5
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 16,}}>3</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 10,}}
                    >
                        <Text style={{fontSize: 6,}}>
                            Luogo previsto per la consegna della merce
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Place of delivery of the goods
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Auslieferungsort des Gutes
                        </Text>
                    </View>
                </View>
                <View style={{flexDirection: "column", marginTop:5}}>
                    <Text style={{fontSize: 10,}}>Geti Wilba GMBH</Text>
                    <Text style={{fontSize: 10,}}>Hansestraße 2, D</Text>
                    <Text style={{fontSize: 10,}}>27432 Bremervörde, Germany</Text>
                </View>


            </View>

        </>
    );
}

export default Rafood3Pdf;
