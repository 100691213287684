import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as tipiCapoApi from "../../../api/anagrafiche/tipiCapoApi";
import * as articoliApi from "../../../api/anagrafiche/articoliApi";
import * as committentiApi from "../../../api/anagrafiche/committentiApi";
import * as produttoriApi from "../../../api/anagrafiche/produttoriApi";
import * as allevamentiApi from "../../../api/anagrafiche/allevamentiApi";
import * as parametriApi from "../../../api/anagrafiche/parametriApi";
import * as camionsApi from "../../../api/anagrafiche/camionsApi";
import * as autistiApi from "../../../api/anagrafiche/autistiApi";
import * as etichetteApi from "../../../api/anagrafiche/etichetteApi";
import * as codiciAIApi from "../../../api/anagrafiche/codiciAIApi";
import * as provinceApi from "../../../api/anagrafiche/provinceApi";
import { UtenteEsterno } from "../ospiti/ospitiSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { toast } from "react-toastify";
import { Lotto, getLotto } from "../pianificazione/pianificazioneSlice";
import { getSchedaCarico } from "../schedeCarico/schedeCaricoSlice";

// export interface User {
//   username: string;
//   password: string;
// }
export interface TipoCapo {
	id: number;
	nome: string;
	descrizione?: string;
	colore?: string;
	pezzatura: string;
	numero_capi_foro: number;
	peso_medio?: number;
	// TODO provare con number
	velocita_processamento?: number;
	minuti_attrezzaggio?: number;
	calcolo_velocita_processamento?: number;
	// calcolo_velocita_processamento_pesata?: number;
}

export interface TipiCapo {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoCapo[];
}

export interface Articolo {
	id: number;
	codice?: string;
	codice_articolo: string;
	descrizione?: string;
	qualita?: string;
	numero_capi_cassa?: number;
	tipo_macellazione?: string;
	tipi_capo: number[];
}

export interface Articoli {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Articolo[];
}

export interface Contatto {
	id: number;
	tipo_contatto: TipoContattoTypes;
	valore?: string;
	riferimento?: string;
}

export interface Allevamento {
	id: number;
	codice_asl: string;
	nome: string;
	indirizzo?: string;
	cap?: number;
	comune?: string;
	provincia?: number;
	provincia_desc?: string;
	distanza?: number;
	distanza_concordata?: number;
	consente_rimorchio: boolean;
	note_di_contatto?: string;
	indirizzo_pesa?: string;
	note?: string;
	contatti?: Contatto[];
	errorsStack?: ErrorsStack;
}

export interface Allevamenti {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Allevamento[];
	errorsStack: ErrorsStack;
}

export enum RegolaLottoTypes {
	S = "Metodo Scarso",
	I = "Lotto Imposto",
	A = "Metodo Amadori",
}

export enum TipoTrasportoTypes {
	V = "Vivo",
	F = "Frigo",
	R = "Rimorchio",
}

export interface Committente {
	id: number;
	nome: string;
	ragione_sociale: string;
	codice_fiscale: string;
	indirizzo: string;
	cap: string;
	piva: string;
	comune: string;
	provincia: string;
	regola_lotto: RegolaLottoTypes;
	gestisce_colore: boolean;
	allevamenti: Allevamento[];
	codice_macello?: string;
	causale_trasporto_default: string;
	ddt_digitali?: boolean;
	annotazioni_bolla?: string;
	listino_trasporti_vivo?: number;
	listino_vendita_articoli?: number;
	committente_detail?: URL;
	contatti: Contatto[];
	utenti_esterni: UtenteEsterno[];
}

export interface Committenti {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Committente[];
	errorsStack: ErrorsStack;
}

export enum TipoContattoTypes {
	M = "M", // mail
	T = "T", //telefono fisso
	C = "C", //cellulare",
}

export interface Produttore {
	id: number;
	nome: string;
	contatti: Contatto[];
}

export interface Produttori {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Produttore[];
}

export interface StatoCamionAllaData {
	stato: number;
	descrizione: string;
	viaggio_lungo: boolean;
	messaggio: string;
}
export interface Camion {
	id: number;
	nome: string;
	targa: string;
	proprieta: boolean;
	numero_fori: number;
	stato_camion_alla_data: StatoCamionAllaData;
	saturazione: number;
	numero_cuccette?: number;
	tara?: number;
	tipo_trasporto?: "V" | "F" | "R";
	autotreno: boolean;
	in_manutenzione: boolean;
	costo_km: number;
	note?: string;
}
export interface Camions {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Camion[];
}
export interface StatoAutistaAllaData {
	stato: number;
	descrizione: string;
	viaggio_lungo: boolean;
	messaggio: string;
}
export interface Autista {
	id: number;
	nome: string;
	cognome: string;
	utente: string;
	viaggi_schedulati: number;
	costo_orario: number;
	dipendente: boolean;
	stato_autista_alla_data: StatoAutistaAllaData;
}
export interface Autisti {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Autista[];
}

export interface Provincia {
	codice_provincia: number;
	codice_targa: string;
	name: string;
	codice_regione: number;
	name_regione: string;
}
export interface Province {
	result_code: number;
	province: Provincia[];
}

export interface Parametri {
	minuti_pausa: number;
	attrezzaggio_standard: number;
	minuti_max_giorno: number;
	minuti_max_sabato: number;
	massimo_fori_giornata: number;
	alert_fori_giornata: number;
	massimo_km_autista_unico: number;
	disinfettante: string;
	peso_gabbione: number;
	data_validta_da: Date | string;
	data_validta_a: Date | string;
}

export interface StoricoParametri {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Parametri[];
	errorsStack: ErrorsStack;
}

export interface Etichetta {
	id: number;
	nome: string;
}
export interface Etichette {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Etichetta[];
	errorsStack: ErrorsStack;
}

export interface CodiceAI {
	id: number;
	nome: string;
}
export interface CodiciAI {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: CodiceAI[];
	errorsStack: ErrorsStack;
}
export interface AnagraficheStrutturaState {
	tipiCapo: TipiCapo;
	articoli: Articoli;
	committenti: Committenti;
	produttori: Produttori;
	allevamenti: Allevamenti;
	storicoParametri: StoricoParametri;
	camions: Camions;
	autisti: Autisti;
	etichette: Etichette;
	codiciAI: CodiciAI;
	province: Province;
	errorsStack: ErrorsStack;
}

const initialState: AnagraficheStrutturaState = {
	tipiCapo: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	articoli: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	committenti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	produttori: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	allevamenti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	storicoParametri: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [
			{
				minuti_pausa: 20,
				attrezzaggio_standard: 4,
				minuti_max_giorno: 390,
				minuti_max_sabato: 300,
				massimo_fori_giornata: 1040,
				alert_fori_giornata: 960,
				massimo_km_autista_unico: 300,
				disinfettante: "Virkon S",
				peso_gabbione: 210,
				data_validta_da: "2020-01-01",
				data_validta_a: "2090-12-31",
			},
		],
		errorsStack: { status: ErrorStatusTypes.OK },
	},

	camions: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	autisti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	etichette: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	codiciAI: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	province: {
		result_code: 0,
		province: [],
	},
	errorsStack: { status: ErrorStatusTypes.OK },
};

export const aggiornaTutteAnagrafiche = createAsyncThunk(
	"tutteAnagrafiche/aggiornaTutteAnagrafiche",
	async (_, thunkApi) => {
		thunkApi.dispatch(fetchParametri());
		thunkApi.dispatch(fetchProvince());
		thunkApi.dispatch(fetchCommittenti());
		thunkApi.dispatch(fetchProduttori());
		thunkApi.dispatch(fetchTipiCapo());
		thunkApi.dispatch(fetchArticoli());
		thunkApi.dispatch(fetchAllevamenti());
		thunkApi.dispatch(fetchCamions());
		thunkApi.dispatch(fetchAutisti());
	}
);

export const fetchTipiCapo = createAsyncThunk(
	"tipiCapo/fetchTipiCapo",
	async (tipiCapo_velocita_processamento?: boolean) => {
		return await tipiCapoApi.fetchTipiCapo(tipiCapo_velocita_processamento);
		//   return user;
	}
);

export const fetchArticoli = createAsyncThunk(
	"articoli/fetchArticoli",
	async () => {
		return await articoliApi.fetchArticoli();
		//   return user;
	}
);

export const fetchCommittenti = createAsyncThunk(
	"committenti/fetchCommittenti",
	async () => {
		return await committentiApi.fetchCommittenti();
		//   return user;
	}
);

export const getCommittente = createAsyncThunk(
	"allevamenti/getCommittente",
	async (committenteId: number) => {
		return await committentiApi.getCommittente(committenteId);
		//   return user;
	}
);

export const fetchProduttori = createAsyncThunk(
	"produttori/fetchProduttori",
	async () => {
		return await produttoriApi.fetchProduttori();
		//   return user;
	}
);

export const fetchAllevamenti = createAsyncThunk(
	"allevamenti/fetchAllevamenti",
	async () => {
		return await allevamentiApi.fetchAllevamenti();
		//   return user;
	}
);

export const getAllevamento = createAsyncThunk(
	"allevamenti/getAllevamento",
	async (allevamentoId: number) => {
		return await allevamentiApi.getAllevamento(allevamentoId);
		//   return user;
	}
);

export const saveAllevamento = createAsyncThunk(
	"allevamenti/saveAllevamento",
	async (parametri: { allevamento: Allevamento; lotto?: Lotto }, thunkApi) => {
		return await allevamentiApi
			.saveAllevamento(parametri.allevamento)
			.then((response) => {
				parametri.lotto?.schede_carico.map((schedaCarico) =>
					thunkApi.dispatch(getSchedaCarico(schedaCarico.id || 0))
				);
				return response;
			});
		//   return user;
	}
);

export const fetchParametri = createAsyncThunk(
	"parametri/fetchParametri",
	async () => {
		return await parametriApi.fetchParametri();
		//   return user;
	}
);

export const fetchCamions = createAsyncThunk(
	"allevamenti/fetchCamions",
	async (data_conteggio?: Date | string) => {
		return await camionsApi.fetchCamions(data_conteggio);
		//   return user;
	}
);

export const getCamion = createAsyncThunk(
	"allevamenti/getCamion",
	async (parametri: { camionId: number; data_conteggio?: Date }) => {
		return await camionsApi.getCamion(
			parametri.camionId,
			parametri?.data_conteggio
		);
		//   return user;
	}
);

export const fetchAutisti = createAsyncThunk(
	"allevamenti/fetchAutisti",
	async (data_conteggio?: Date | string) => {
		return await autistiApi.fetchAutisti(data_conteggio);
		//   return user;
	}
);

export const getAutista = createAsyncThunk(
	"allevamenti/getAutista",
	async (parametri: { autistaId: number; data_conteggio?: Date }) => {
		return await autistiApi.getAutista(
			parametri.autistaId,
			parametri?.data_conteggio
		);
		//   return user;
	}
);

export const fetchEtichette = createAsyncThunk(
	"anagrafiche/fetchEtichette",
	async () => {
		return await etichetteApi.fetchEtichette();
		//   return user;
	}
);

export const fetchCodiciAI = createAsyncThunk(
	"anagrafiche/fetchCodiciAI",
	async () => {
		return await codiciAIApi.fetchCodiciAI();
		//   return user;
	}
);
export const fetchProvince = createAsyncThunk(
	"province/fetchProvince",
	async () => {
		return await provinceApi.fetchProvince();
		//   return user;
	}
);

export const anagraficheSlice = createSlice({
	name: "anagrafiche",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch tipi capo
		builder.addCase(fetchTipiCapo.pending, (state, action) => {});
		builder.addCase(fetchTipiCapo.fulfilled, (state, action) => {
			state.tipiCapo = action.payload;
		});
		builder.addCase(fetchTipiCapo.rejected, (state, action) => {});

		// fetch articoli
		builder.addCase(fetchArticoli.pending, (state, action) => {});
		builder.addCase(fetchArticoli.fulfilled, (state, action) => {
			state.articoli = action.payload;
		});
		builder.addCase(fetchArticoli.rejected, (state, action) => {});

		// fetch committenti
		builder.addCase(fetchCommittenti.pending, (state, action) => {
			state.committenti.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchCommittenti.fulfilled, (state, action) => {
			state.committenti = action.payload;

			state.committenti.errorsStack = {
				...state.committenti.errorsStack,
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchCommittenti.rejected, (state, action) => {
			state.committenti.errorsStack.status = ErrorStatusTypes.ERROR;
		});

		// get committenti
		builder.addCase(getCommittente.pending, (state, action) => {});
		builder.addCase(getCommittente.fulfilled, (state, action) => {
			state.committenti.results = state.committenti.results.map(
				(committente) => {
					return committente.id == action.payload.id
						? action.payload
						: committente;
				}
			);
		});
		builder.addCase(getCommittente.rejected, (state, action) => {});

		// fetch Produttori
		builder.addCase(fetchProduttori.pending, (state, action) => {});
		builder.addCase(fetchProduttori.fulfilled, (state, action) => {
			state.produttori = action.payload;
		});
		builder.addCase(fetchProduttori.rejected, (state, action) => {});

		// fetch allevamenti
		builder.addCase(fetchAllevamenti.pending, (state, action) => {
			state.allevamenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchAllevamenti.fulfilled, (state, action) => {
			state.allevamenti = action.payload;

			state.allevamenti.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchAllevamenti.rejected, (state, action) => {
			state.allevamenti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};

			toast.error(
				"Errore nello scaricamento Allevamenti:" + action?.error?.message || ""
			);
		});

		// get allevamenti
		builder.addCase(getAllevamento.pending, (state, action) => {});
		builder.addCase(getAllevamento.fulfilled, (state, action) => {
			state.allevamenti.results = state.allevamenti.results.map(
				(allevamento) => {
					return allevamento.id == action.payload.id
						? action.payload
						: allevamento;
				}
			);
		});
		builder.addCase(getAllevamento.rejected, (state, action) => {});

		// get allevamenti
		builder.addCase(saveAllevamento.pending, (state, action) => {
			state.allevamenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
				saving: true,
			};
		});
		builder.addCase(saveAllevamento.fulfilled, (state, action) => {
			state.allevamenti.results = state.allevamenti.results.map(
				(allevamento) => {
					return allevamento.id == action.payload.id
						? action.payload
						: allevamento;
				}
			);
			state.allevamenti.errorsStack = {
				status: ErrorStatusTypes.OK,
				saving: false,
			};
			toast.success("Dati allevamento salvati.");
		});
		builder.addCase(saveAllevamento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allevamenti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				saving: false,
			};

			state.allevamenti.results = state.allevamenti.results.map(
				(allevamento) => {
					if (allevamento.id == action.meta.arg.allevamento.id) {
						return {
							...allevamento,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return allevamento;
					}
				}
			);
		});

		// fetch parametri
		builder.addCase(fetchParametri.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchParametri.fulfilled, (state, action) => {
			state.storicoParametri = action.payload;
			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchParametri.rejected, (state, action) => {
			toast.warning("Parametri lato server mancanti.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});

		// fetch camions
		builder.addCase(fetchCamions.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchCamions.fulfilled, (state, action) => {
			state.camions = action.payload;
			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchCamions.rejected, (state, action) => {
			toast.warning("Errore nel caricmento Camion mancanti.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});

		// get camion
		builder.addCase(getCamion.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getCamion.fulfilled, (state, action) => {
			state.camions.results = state.camions.results.map((camion) => {
				return camion.id == action.payload.id ? action.payload : camion;
			});

			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getCamion.rejected, (state, action) => {
			toast.warning("Errore in aggiornamento Camion.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});

		// fetch autisti
		builder.addCase(fetchAutisti.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchAutisti.fulfilled, (state, action) => {
			state.autisti = action.payload;
			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAutisti.rejected, (state, action) => {
			toast.warning("Errore in aggiornamento Autisti.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});

		// get autista
		builder.addCase(getAutista.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getAutista.fulfilled, (state, action) => {
			state.autisti.results = state.autisti.results.map((autista) => {
				return autista.id == action.payload.id ? action.payload : autista;
			});
			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getAutista.rejected, (state, action) => {
			toast.warning("Errore in aggiornamento Autista.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});

		// fetch autisti
		builder.addCase(fetchEtichette.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchEtichette.fulfilled, (state, action) => {
			state.etichette = action.payload;
			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchEtichette.rejected, (state, action) => {
			toast.warning("Errore in aggiornamento Etichette.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});

		// fetch codici AI
		builder.addCase(fetchCodiciAI.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchCodiciAI.fulfilled, (state, action) => {
			state.codiciAI = action.payload;
			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchCodiciAI.rejected, (state, action) => {
			toast.warning("Errore in aggiornamento Etichette.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});

		// fetch province
		builder.addCase(fetchProvince.pending, (state, action) => {
			state.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchProvince.fulfilled, (state, action) => {
			state.province = action.payload;
			state.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchProvince.rejected, (state, action) => {
			toast.warning("Errore in aggiornamento Province.");
			state.errorsStack = { status: ErrorStatusTypes.ERROR };
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = anagraficheSlice.actions;

export const anagraficheReducer = anagraficheSlice.reducer;
