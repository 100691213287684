import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface RafoodSub6789PdfProps {
    // colore: string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const RafoodSub6789Pdf = ({}: RafoodSub6789PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:5
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <View style={{flexDirection: "column", width:'25%'}}>
                        <Text style={{fontSize: 5,}}>
                            Classe
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Class/Klasse
                        </Text>
                    </View>
                    <View style={{flexDirection: "column", width:'25%'}}>
                        <Text style={{fontSize: 5,}}>
                            Cifra
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Number/Ziffer
                        </Text>
                    </View>
                    <View style={{flexDirection: "column", width:'25%'}}>
                        <Text style={{fontSize: 5,}}>
                            Lettera
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Letter/Buchstabe
                        </Text>
                    </View>
                    <View style={{flexDirection: "column", width:'25%'}}>
                        <Text style={{fontSize: 5,}}>
                            (ADR)
                        </Text>
                    </View>
                </View>


            </View>

        </>
    );
}

export default RafoodSub6789Pdf;
