import * as React from "react";
import { Routes_path } from "../../routerApp";
import { useNavigate, Path, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import BalanceIcon from "@mui/icons-material/Balance";
import BuildIcon from "@mui/icons-material/Build";


interface BreadcrumbsProps {
	identificativoPosizione?: string | number;
}


const PesaturaPalletLottoBreadcrumbs = ({
											identificativoPosizione,
										}: BreadcrumbsProps) => {
	return (
		<Breadcrumbs aria-label="breadcrumb">
			<IconButton
				key={"home"}
				aria-label="home"
				size="small"
				component={Link}
				to={{
					pathname: Routes_path.HOME,
				}}
				// state={{
				// 	lavorazine_id: params.value,
				// }}
			>
				<HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Home
			</IconButton>
			<IconButton
				key={"home"}
				aria-label="modifica lavorazione"
				size="small"
				component={Link}
				to={{
					pathname: Routes_path.PESATURA_PALLET,
				}}
			>
				<BalanceIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Pesatura Pallet
			</IconButton>
			<IconButton
				key={"home"}
				aria-label="modifica lavorazione"
				size="small"
			>
				<BalanceIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Lotto {identificativoPosizione}
			</IconButton>
		</Breadcrumbs>
	);
};

export default PesaturaPalletLottoBreadcrumbs;
