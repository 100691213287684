import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood11PdfProps {
    // colore: string
    packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood11Pdf = ({packingList}: Rafood11PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:2,
                height: 120
            }}>
                <View style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 9,}}>11</Text>
                    <View style={{flexDirection: "column", marginLeft: 2,}}>
                        <Text style={{fontSize: 5,}}>
                            Peso Lordo in kg
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Gross weight in kg
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Bruttogewicht in kg
                        </Text>
                    </View>
                </View>

                    <Text style={{fontSize: 10, marginTop:5}}>
                        {packingList.peso_lordo && Intl.NumberFormat("it-IT").format(packingList.peso_lordo) + " kg"}
                    </Text>
                    <Text style={{fontSize: 8,}}>net weigth
                    </Text>
                <Text style={{fontSize: 10,}}>
                    {packingList.peso_netto && Intl.NumberFormat("it-IT").format(packingList.peso_netto) + " kg"}
                </Text>

            </View>

        </>
    );
}

export default Rafood11Pdf;
