import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood21PdfProps {
    // colore: string
    packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood21Pdf = ({packingList}: Rafood21PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:2,
                // height: 50
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 9,}}>21</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 2,}}
                    >
                        <Text style={{fontSize: 5,}}>
                            Compilato a / Data
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Estabilished in / Date
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Ausgefertigt in / Date
                        </Text>
                    </View>

                    <Text style={{fontSize: 12, marginLeft:5 }}>
                        Padova (PD) il {moment(packingList.data).format('DD/MM/YYYY')}
                    </Text>
                </View>


            </View>

        </>
    );
}

export default Rafood21Pdf;
