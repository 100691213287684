import React from "react";
import {
	DestinazioneTrasporto,
	// VettoreTrasporto,
} from "components/main/bolle_fatture/bolleFattureSlice";
import {PackingList,} from "components/main/pesaturaPallet/pesaturaPalletSlice";

import { Committente } from "components/main/anagrafiche/anagraficheSlice";
import DdtContoTerziPdf from "./DdtContoTerziPdf";
// import ResaLavorazioniPDF from "./ResaLavorazioniPDF";
import { Page, Document } from "@react-pdf/renderer";

interface DocumentoContoTerziDdtProps {
	packingList: PackingList;
	destinatario: Committente;
	destinazione?: DestinazioneTrasporto;
	// vettore?: VettoreTrasporto;
}

const DocumentoDdtContoTerzi = ({
	packingList,
	destinatario,
	destinazione,
	// vettore,
}: DocumentoContoTerziDdtProps) => {
	return (
		<Document>
			<Page
				size="A4"
				orientation="portrait"
				style={{ backgroundColor: "#fff", paddingTop: 20 }}
				key={"packingList_" + packingList?.id}
			>
				{packingList && (
					<DdtContoTerziPdf
						packingList={packingList}
						destinatario={destinatario}
						destinazione={destinazione}
						// vettore={vettore}
					/>
				)}
			</Page>
		</Document>
	);
};

export default DocumentoDdtContoTerzi;
