import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood4PdfProps {
    // colore: string
    packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood4Pdf = ({packingList}: Rafood4PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:5
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 16,}}>4</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 10,}}
                    >
                        <Text style={{fontSize: 6,}}>
                            Luogo e data della presa in carico della merce
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Place and date of taking over the goods
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Ort und Tag der Übername des Gutes
                        </Text>
                    </View>
                </View>
                <View style={{flexDirection: "column", marginTop:5}}>
                    <Text style={{fontSize: 10,}}>Scarso Romualdo e Figli spa</Text>
                    <Text style={{fontSize: 10,}}>via G. Garibaldi 43</Text>
                    <Text style={{fontSize: 10,}}>35020, Ponte San Nicolò (PD)</Text>
                    <Text style={{fontSize: 10,}}>{moment(packingList.data).format('DD/MM/YYYY')}</Text>
                </View>


            </View>

        </>
    );
}

export default Rafood4Pdf;
