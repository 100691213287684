import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


// Create styles
const styles = StyleSheet.create({
    rotatedSection: {
        transform: "rotate(-90deg)",
        textAlign: "left",
        marginTop: 280,
        width: 600,
        marginLeft: -285,
        // borderStyle:"solid",
        // borderColor:"#000",
        // borderWidth:1,
    },
    rotatedText: {
        fontSize: 6,
    },
});

interface RafoodSpallaDestraPdfProps {
    // colore:string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const RafoodSpallaDestraPdf = ({}: RafoodSpallaDestraPdfProps) => {

    return (
        <>
            <View key={'colonne_principali'} style={{flexDirection: "column", justifyContent: "center",}}>
                <View key={'rettangolo_colorato'} style={{height: '200px',}}></View>
                <View key={'testo_ruotato'}>
                    <View style={styles.rotatedSection}>
                        <Text style={styles.rotatedText}>
                            Per le merci pericolose indicare, oltre la denominazione corrente, la classe, la cifra e se del caso la lettera.
                        </Text>
                        <Text style={styles.rotatedText}>
                            In case of dangerous goods mention besides the possible certification, on the last line of
                            the column the particulars of the class, the number and the letter, if any.
                        </Text>
                        <Text style={styles.rotatedText}>
                            Bei gefährlichen Gütern ist, außer der eventuellen
                            Bescheinigung auf der letzen Linie der Rubrik anzugeben: die Klasse, die Ziffer sowie
                            gegebenenfalls der Buchstabe.
                        </Text>

                    </View>
                </View>


            </View>

        </>
    );
}

export default RafoodSpallaDestraPdf;
