import React, {useEffect} from "react";
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import {TextField, Button, Grid, Box} from "@mui/material";
import moment from "moment";
import {RootState, useAppDispatch} from "../../../redux/store";


import {NumericFormat} from "react-number-format";
import Typography from "@mui/material/Typography";
import {savePackingList, PackingList} from "./pesaturaPalletSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";

interface PackingListFormProps {
    packingList: PackingList;
    consentiModifica: boolean
}

const PackingListForm: React.FC<PackingListFormProps> = ({
                                                             packingList,
                                                             consentiModifica = false
                                                         }: PackingListFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<PackingList>();

    const dispatch = useAppDispatch();
    const listaCampi = Object.keys(packingList) as Array<keyof PackingList>;

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, packingList[field]);
        });
    }, [
        listaCampi.forEach((field) => {
            packingList[field];
        }),
        packingList.id,
    ]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            packingList?.errorsStack?.fieldsErrors &&
            packingList.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message:
                    packingList.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        packingList?.errorsStack?.fieldsErrors,
    ]);

    const handlerSavePackingList = () => {
        const formValues = getValues();
        const packingListToSave: PackingList = {...formValues, id: packingList.id,};
        dispatch(savePackingList({packingListToSave: packingListToSave}));
    }

    const onSubmit: SubmitHandler<PackingList> = () => {
        handlerSavePackingList();
    };

    return (
        <Box sx={{p: 0.5}} key={`packingList_${packingList.id}`}>
            <form onSubmit={handleSubmit(onSubmit)} key={`form_${packingList.id}`}>
                <Stack direction="row" spacing={2} sx={{p: 2}}>
                {/*<Typography variant={"h6"}>DDT committente:</Typography>*/}

                        <Controller
                            name="numero_ddt_committente"
                            control={control}
                            // rules={{
                            //     required: "Il numero di cassette è obbligatorio",
                            //     min: { value: 1, message: "Deve essere almeno 1" },
                            // }}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label="Ddt committente"
                                    type="number"
                                    disabled={!consentiModifica}
                                    autoComplete="off"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    error={!!errors.numero_ddt_committente}
                                    helperText={errors.numero_ddt_committente?.message}
                                    onBlur={() => handlerSavePackingList()}
                                />
                            )}
                        />
                </Stack>
            </form>
        </Box>
    );
};

export default PackingListForm;
