import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import {
	DocumentiDiTrasporto,
	DocumentoDiTrasporto,
	DettaglioDDT,
	DestinazioneTrasporto,
	VettoreTrasporto,
	LavorazioniNonCompletate,
	saveDocumentoDiTrasporto,
	fetchDestinazioniTrasporto,
	fetchLavorazioniNonCompletate,
	getDocumentoDiTrasporto,
	fetchVettoriTrasporto,
	fetchListiniVenditaArticoli,
	setDdtCorrente,
	resetLavorazioniNonCompletate,
	aggiungiLavorazioniDdt,
	aggiungiDettagliDdt,
	saveDettaglioDocumentoDiTrasporto,
	deleteDettaglioDocumentoDiTrasporto,
} from "./bolleFattureSlice";
import DdtTestataForm from "./DdtTestataForm";
import LavorazioniNonCompletateList from "./LavorazioniNonCompletateList";
import DettaglioDDTList from "./DettaglioDDTList";
import {
	Committente,
	Articolo,
	fetchCommittenti,
	fetchArticoli,
} from "../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import PrintIcon from "@mui/icons-material/Print";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const DocumentoDiTrasportoPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const documentiDiTrasporto: DocumentiDiTrasporto = useSelector<
		RootState,
		DocumentiDiTrasporto
	>((state) => state.bolleFattureState.documentiDiTrasporto);

	const ddtCorrente_id = useSelector<RootState, number | null>(
		(state) => state.bolleFattureState.ddtCorrente_id
	);

	type LocationState = {
		documentoDiTrasporto_id?: number | null;
	};
	const location = useLocation();
	const { documentoDiTrasporto_id } = (location.state as LocationState) || {
		documentoDiTrasporto_id: null,
	};

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		dispatch(fetchCommittenti());
		dispatch(fetchArticoli());
		dispatch(fetchDestinazioniTrasporto());
		dispatch(fetchVettoriTrasporto());
		dispatch(fetchListiniVenditaArticoli());
		ddtCorrente_id && dispatch(getDocumentoDiTrasporto(ddtCorrente_id));
		documentoDiTrasporto.destinatario &&
			dispatch(
				fetchLavorazioniNonCompletate(documentoDiTrasporto.destinatario)
			);

		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		dispatch(
			setDdtCorrente({ ddtCorrente_id: documentoDiTrasporto_id || null })
		);
		dispatch(resetLavorazioniNonCompletate());
	}, []);

	// ############# Per resettare all'uscita ###############################
	useEffect(
		() => () => {
			dispatch(setDdtCorrente({ ddtCorrente_id: null }));
		},
		[]
	);
	// #######################################################################

	useEffect(() => {
		if (ddtCorrente_id != null || ddtCorrente_id != undefined) {
			dispatch(getDocumentoDiTrasporto(ddtCorrente_id));
		}
	}, [ddtCorrente_id, refresh]);

	const committenti = useSelector<RootState, Committente[]>(
		(state) => state.anagrafiche.committenti.results
	);
	const articoli = useSelector<RootState, Articolo[]>(
		(state) => state.anagrafiche.articoli.results
	);
	const destinazioniTrasporto = useSelector<RootState, DestinazioneTrasporto[]>(
		(state) => state.bolleFattureState.destinazioniTrasporto.results
	);
	const vettoriTrasporto = useSelector<RootState, VettoreTrasporto[]>(
		(state) => state.bolleFattureState.vettoriTrasporto.results
	);
	const lavorazioniNonCompletate = useSelector<
		RootState,
		LavorazioniNonCompletate
	>((state) => state.bolleFattureState.lavorazioniNonCompletate);

	const ddtNuovoFieldsErrors: ErrorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.bolleFattureState.documentiDiTrasporto.errorsStack
	);

	const [documentoDiTrasporto, setdocumentoDiTrasporto] =
		useState<DocumentoDiTrasporto>({
			id: null,
			progressivo: null,
			causale_trasporto: "L",
			aspetto_esteriore_beni: "A",
			data_documento: new Date(),
			data_ora_trasporto: new Date(),
			destinatario: undefined,
			destinazione: undefined,
			vettore: undefined,
			annotazioni: "",
			righe_dettaglio: [],
			errorsStack: ddtNuovoFieldsErrors,
		});

	useEffect(() => {
		setdocumentoDiTrasporto({
			...documentoDiTrasporto,
			errorsStack: ddtNuovoFieldsErrors,
		});
	}, [ddtNuovoFieldsErrors]);

	const documentoDiTrasportoEsistente: DocumentoDiTrasporto | undefined =
		useSelector<RootState, DocumentoDiTrasporto | undefined>((state) =>
			state.bolleFattureState.documentiDiTrasporto.results.find(
				(documentoDiTrasporto) => documentoDiTrasporto.id == ddtCorrente_id
			)
		);

	useEffect(() => {
		documentoDiTrasportoEsistente &&
			setdocumentoDiTrasporto(documentoDiTrasportoEsistente);
	}, [ddtCorrente_id, documentoDiTrasportoEsistente]);

	const handlerSaveDocumentoDiTrasporto = (
		documentoDiTrasportoToSave: DocumentoDiTrasporto
	) => {
		dispatch(saveDocumentoDiTrasporto({ documentoDiTrasportoToSave }));
	};

	useEffect(() => {
		documentoDiTrasporto.destinatario &&
			dispatch(
				fetchLavorazioniNonCompletate(documentoDiTrasporto.destinatario)
			);
	}, [documentoDiTrasporto.destinatario]);

	const handlerAggiungiLavorazioniDdt = (lavorazioni: number[]) => {
		const parametri: { ddt: DocumentoDiTrasporto; lavorazioni: number[] } = {
			ddt: documentoDiTrasporto,
			lavorazioni: lavorazioni,
		};
		dispatch(aggiungiLavorazioniDdt(parametri));
	};

	const handlerAggiungiDettagliDdt = (dettaglioDdt: DettaglioDDT) => {
		const rDdt: DettaglioDDT = {
			...dettaglioDdt,
			ddt: documentoDiTrasporto.id || 0,
		};

		const parametri: { ddt: DocumentoDiTrasporto; dettaglioDdt: DettaglioDDT } =
			{
				ddt: documentoDiTrasporto,
				dettaglioDdt: rDdt,
			};
		dettaglioDdt.id
			? dispatch(saveDettaglioDocumentoDiTrasporto(parametri))
			: dispatch(aggiungiDettagliDdt(parametri));
	};

	const handlerDeleteDettaglioDocumentoDiTrasporto = (rddt_id: number) => {
		const parametri: { ddt: DocumentoDiTrasporto; rddt_id: number } = {
			ddt: documentoDiTrasporto,
			rddt_id: rddt_id,
		};
		dispatch(deleteDettaglioDocumentoDiTrasporto(parametri));
	};

	const [nuovoDettaglioDdt, setNuovoDettaglioDdt] = useState<DettaglioDDT>({
		ddt: documentoDiTrasporto.id || 0,
		colli: 0,
		quantita: 0,
		storico_codice_tracciabilita: "",
		storico_articolo: "",
		storico_descrizione: "",
		storico_prezzo: 0,
		errorsStack: ddtNuovoFieldsErrors,
	});

	useEffect(() => {
		documentoDiTrasporto.id &&
			setNuovoDettaglioDdt({
				...nuovoDettaglioDdt,
				ddt: documentoDiTrasporto.id,
			});
	}, [documentoDiTrasporto.id]);
	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					documentiDiTrasporto.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={
					documentoDiTrasporto.id
						? "Documento di Trasporto n° " +
						  (documentoDiTrasporto.progressivo || documentoDiTrasporto.id)
						: "Nuovo documento di trasporto"
				}
				dataPresente={false}
				setData={() => {}}
				refresh={() => {
					setRefresh(true);
				}}
			/>

			<Box
				sx={{
					p: 1,
					// elevation: 0,
					margin: 5,
					marginY: 2,
					// marginBottom: 5,
					padding: 5,
					paddingBottom: 3,
					backgroundColor: "#f5f5f5",
					border: 0,
					// borderStyle: "solid",
					borderRadius: 4,
					boxShadow: 2,
				}}
			>
				{documentoDiTrasporto && (
					<DdtTestataForm
						key={documentoDiTrasporto.id || 0}
						documentoDiTrasporto={documentoDiTrasporto}
						committenti={committenti}
						destinazioniTrasporto={destinazioniTrasporto}
						vettoriTrasporto={vettoriTrasporto}
						saveDocumentoDiTrasporto={handlerSaveDocumentoDiTrasporto}
					/>
				)}
			</Box>
			<Grid
				container
				// sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item md={6}>
					{documentoDiTrasporto && documentoDiTrasporto.destinatario && (
						<LavorazioniNonCompletateList
							lavorazioniNonCompletate={lavorazioniNonCompletate}
							committente={committenti.find(
								(c) => c.id == documentoDiTrasporto.destinatario
							)}
							aggiungiLavorazioniDdt={handlerAggiungiLavorazioniDdt}
						/>
					)}
				</Grid>
				<Grid item md={6}>
					{documentoDiTrasporto.id && (
						<DettaglioDDTList
							documentoDiTrasporto={documentoDiTrasporto}
							articoli={articoli}
							nuovoDettaglioDdt={nuovoDettaglioDdt}
							aggiungiDettagliDdt={handlerAggiungiDettagliDdt}
							deleteDettaglioDocumentoDiTrasporto={
								handlerDeleteDettaglioDocumentoDiTrasporto
							}
						/>
					)}
				</Grid>
			</Grid>
			<Stack direction="row" spacing={2} sx={{ margin: 2 }}>
				<Button
					variant="contained"
					component={Link}
					to={{
						pathname: Routes_path.DOCUMENTI_TRASPORTO,
					}}
					// state={{
					// 	documentoDiTrasporto_id: documentoDiTrasporto?.id,
					// }}
					endIcon={<KeyboardReturnIcon />}
				>
					Torna alla lista DDT
				</Button>
				{committenti.length > 0 && (
					<Button
						sx={{ marginTop: 2, marginBottom: 0 }}
						startIcon={<PrintIcon />}
						variant="contained"
						component={Link}
						to={{
							pathname: Routes_path.STAMPA_DOCUMENTO_TRASPORTO +
								documentoDiTrasporto.id,
						}}
						state={{
							documentoDiTrasporto_id: documentoDiTrasporto.id,
						}}
						disabled={!(documentoDiTrasporto.righe_dettaglio.length > 0)}
					>
						Stampa DDT
					</Button>
				)}
				{documentoDiTrasportoEsistente &&
					committenti.length > 0 &&
					committenti.find((c) => c.id == documentoDiTrasporto.destinatario)
						?.ddt_digitali && (
						<Button
							startIcon={<CloudUploadIcon />}
							variant="contained"
							disabled={!(documentoDiTrasporto.righe_dettaglio.length > 0)}
							onClick={() => {
								dispatch(
									saveDocumentoDiTrasporto({
										documentoDiTrasportoToSave: documentoDiTrasportoEsistente,
										azione: "manda_ddt_elettronico",
									})
								);
							}}
						>
							Manda DDT elettronico
						</Button>
					)}
				{documentoDiTrasportoEsistente &&
					documentoDiTrasportoEsistente.estremi_invio && (
						<Typography>
							{documentoDiTrasportoEsistente.estremi_invio}
						</Typography>
					)}
			</Stack>
		</>
	);
};

export default DocumentoDiTrasportoPage;
